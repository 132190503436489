import { Box, Container, Stack } from '@chakra-ui/react'
import groupBy from 'lodash/groupBy'
import type { ChangeEvent, FC } from 'react'
import { useState } from 'react'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import SelectInput from '@app/next/forms/selectInput'
import Goals from '@app/pages/goals/components/goals'
import EmptyState from '@app/shared/emptyState/emptyState'
import { Card } from '@app/shared/layout'
import type { Goal } from '@graphql/types'
import { GoalStatusEnum } from '@graphql/types'

const Index: FC = () => {
  const [goalFilter, setGoalFilter] = useState<GoalStatusEnum>(GoalStatusEnum.Current)
  const goals = useGetObjectsByProperties('goal', {
    status: goalFilter
  }) as Goal[]
  const groupedGoals = groupBy(goals || [], 'metricId')

  const handleFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setGoalFilter(e.target.value as GoalStatusEnum)
  }

  return (
    <Box w="100%" h="100%" p={4} bgColor="bg.muted">
      <Container maxW="3xl">
        <Stack spacing={8}>
          <Card>
            <SelectInput label="Status" placeholder="" value={goalFilter} onChange={handleFilterChange}>
              <option value={GoalStatusEnum.Past}>Past</option>
              <option value={GoalStatusEnum.Current}>Current</option>
              <option value={GoalStatusEnum.Future}>Future</option>
            </SelectInput>
          </Card>
          <Stack spacing={4}>
            {Object.keys(groupedGoals).map((goalKey) => (
              <Card key={goalKey}>
                <Goals goals={groupedGoals[goalKey]} />
              </Card>
            ))}
          </Stack>
          {goals.length === 0 ? <EmptyState m={0} /> : null}
        </Stack>
      </Container>
    </Box>
  )
}

export default Index

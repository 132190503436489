import { HStack, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import GoalProgress from '@app/pages/goals/components/goalProgress'
import GoalStat from '@app/pages/goals/components/goalStat'
import { formatTotal } from '@app/utils/metricHelpers'
import type { Goal as GoalType, Metric } from '@graphql/types'

interface Props {
  goal: GoalType
  metric: Metric
}

const Goal: FC<Props> = ({ goal, metric }) => (
  <Stack key={goal.id} p={4} borderRadius="md" bgColor="bg.surface">
    <GoalProgress goal={goal} metric={metric} display="standalone" />
    <HStack justify="space-between">
      <GoalStat title="Starting" value={formatTotal(goal.startValue, metric.displayFormat, metric)} />
      <GoalStat title="Target" value={formatTotal(goal.targetValue, metric.displayFormat, metric)} />
      <GoalStat title="Actual" value={formatTotal(goal.currentValue, metric.displayFormat, metric)} />
      <GoalStat
        title="Percent"
        value={Intl.NumberFormat(undefined, { style: 'percent' }).format(goal.percentageComplete / 100)}
      />
    </HStack>
  </Stack>
)

export default Goal
